
.Sidebar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: calc(60px + 34px);
    background-color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
    /* background-color: rgb(40, 42, 45); */
    backdrop-filter: blur(24px);
    box-sizing: border-box;
}

.brand {
    margin-top: 8px;
    margin-left: 10px;
    margin-bottom: 16px;
    width: 57px;
    height: 44px;
    background-repeat: no-repeat;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.account {
    display: flex;
    padding: 24px 24px;
    box-sizing: border-box;
    height: 92px;
    cursor: pointer;
}

.accountPhoto {
    margin-right: 12px;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: rgba(240, 240, 240, 1.0);
    background-size: cover;
}

.accountName {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: rgb(40, 42, 45);    
}

.accountGroup {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: rgb(128, 128, 128);
}

.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-top: 10px; */
    /* justify-content: space-between; */
    width: 100%;
    /* height: 60px; */
    padding: 16px 16px;
    box-sizing: border-box;
}

.link {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 5px;
    opacity: 0.5;
}

.link.selected {
    opacity: 1.0;
    /* background-color: rgb(240, 240, 240); */
}

.tool {
    width: 40px;
    height: 40px;
    background-color: rgba(246, 246, 246, 0.1);
}

.icon {
    /* margin-right: 14px; */
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    /* background-color: gray; */
}

.name {
    display: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.2px;
    color: rgb(40, 42, 45);
}

@media (min-width: 900px) {
    .Sidebar {
        height: 100%;
    }
}