
.SuggestionBadgeView {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.12);
    overflow: hidden;
    opacity: 0.0;
    pointer-events: none;
    transition: 0.2s opacity ease;
}

.SuggestionBadgeView.visible {
    opacity: 1.0;
    pointer-events: auto;
}

.container {
    position: relative;
    width: 600px;
    /* height: 534px; */
    height: 246px;
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    overflow: hidden;
    /* box-shadow: 1px 1px 32px rgba(40, 42, 45, 0.1); */
}

.message {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    /* border-radius: 16px; */
    border-radius: 12px;
    border: 1.5px solid rgba(128, 128, 128, 0.1);
    resize: none;
    min-height: 100px;
    padding: 10px 14px;
    box-sizing: border-box;
    color: rgba(40, 42, 45, 1.0);
}

.message::placeholder {
    color: rgb(128, 128, 128);
}

.message:focus {
    border: 1.5px solid rgba(122, 193, 66, 1.0);
}

.answerInput {
    position: relative;
    margin-top: 12px;
}

.answerInput:first-child {
    margin-top: 0px;
}

.answer {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    /* border-radius: 16px; */
    border-radius: 12px;
    border: 1.5px solid rgba(128, 128, 128, 0.1);
    resize: none;
    /* min-height: 100px; */
    padding: 10px 14px;
    box-sizing: border-box;
    color: rgba(40, 42, 45, 1.0);
}

.answer::placeholder {
    color: rgb(128, 128, 128);
}

.answer:focus {
    border: 1.5px solid rgba(122, 193, 66, 1.0);
}

.answerRemove {
    position: absolute;
    top: 7px;
    right: 14px;
    width: 30px;
    height: 30px;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 21px 21px;
    cursor: pointer;
    opacity: 0.3;
    transition: 0.3s opacity ease;
}

.answerRemove:hover {
    opacity: 1.0;
}

.newAnswer {
    display: flex;
    margin-top: 12px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    /* border-radius: 16px; */
    border-radius: 12px;
    border: 1.5px solid rgba(128, 128, 128, 0.1);
    resize: none;
    /* min-height: 100px; */
    padding: 10px 14px;
    box-sizing: border-box;
    color: rgba(40, 42, 45, 1.0);
    cursor: pointer;
}

.newAnswer:first-child {
    margin-top: 0px;
}

.newAnswerIcon {
    margin-right: 10px;
    width: 23px;
    height: 23px;
    background-size: 100% 100%;
}

.newAnswerText {
    font-size: 14px;
}

.response {
    letter-spacing: -0.2px;
    padding: 12px 0px;
    box-sizing: border-box;
    background-color: gray;
}

.profile {
    padding: 0px 16px;
    box-sizing: border-box;
}

.section {
    margin-top: 20px;
    width: 100%;
    /* background-color: aliceblue; */
}

.sectionTitle {
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.15px;
    color: rgb(100, 100, 100);
    /* text-transform: uppercase; */
    /* background-color: beige; */
}

.sectionContent {
    /* display: flex; */
    margin-top: 12px;
    /* height: 160px; */
    padding: 0px 16px;
    box-sizing: border-box;
    overflow: scroll;
    /* background-color: brown; */
}

.listContent {
    margin-top: 6px;
    padding: 0px 16px;
    box-sizing: border-box;
    overflow: scroll;
}

.sectionContent::-webkit-scrollbar {
    display: none;
}

.badgeItem:last-of-type {
    margin-right: 0px;
}

.badgeItemIcon {
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    background-size: 100% 100%;
    /* background-color: beige; */
}

.badgeItemPhoto {
    position: relative;
    /* margin-top: 5px; */
    margin-right: 0px; /* 8 indent for nothing */
    width: 84px;
    height: 84px;
    flex-shrink: 0;
    background-color: rgb(240, 240, 240);
    box-sizing: border-box;
    border-radius: 42px;
    overflow: hidden;
}

.badgeItemPhoto img {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.featuredIcon {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 36px;
    height: 36px;
    background-size: 32px 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(122, 193, 66, 1.0);
    border-radius: 18px;
    flex-shrink: 0;
    /* box-shadow: 0px 1.5px 19px rgba(122, 193, 66, 0.206); */
    margin-top: 4px;
    font-size: 15px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: rgb(255, 255, 255);
    text-align: center;
}

.badgeItemName {
    margin-top: 0px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.5px;
    text-align: center;
}

.listItem {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    height: 100%;
    display: flex;
    flex-shrink: 0;
    margin-right: 16px;
    /* max-width: 160px; */
    padding: 10px 0px;
    box-sizing: border-box;
    /* background-color: rgb(243, 241, 239); */
    border-radius: 16px;
    /* border: 1px solid rgba(128, 128, 128, 0.1); */
}

.listItemName {
    font-size: 17px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.2px;
}

.listItemPhoto {
    position: relative;
    /* margin-top: 5px; */
    margin-right: 16px; /* 8 indent for nothing */
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    background-color: rgb(240, 240, 240);
    box-sizing: border-box;
    border-radius: 22px;
    overflow: hidden;
}

.listItemPhoto img {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.actions {
    display: flex;
    margin-top: 16px;
    padding: 0px 16px;
    box-sizing: border-box;
}

.action {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    /* width: 100%; */
    height: 44px;
    padding: 0px 30px;
    background-color: rgba(122, 193, 66, 1.0);
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: 0.3s opacity ease;
}

.actionSecondary {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    /* width: 100%; */
    height: 44px;
    padding: 0px 30px;
    background-color: rgb(240, 240, 240);
    border-radius: 22px;
    color: rgb(40, 42, 45);
    cursor: pointer;
    transition: 0.3s opacity ease;
}

.action:hover {
    opacity: 0.8;
}

.actionSecondary:hover {
    opacity: 0.8;
}

.actionIcon {
    position: absolute;
    top: 10px;
    left: 16px;
    width: 30px;
    height: 30px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    display: none;
}

.actionTitle {
    font-size: 14px;
    font-weight: 600;
    /* color: white; */
}

.items {
    padding: 0px 0px;
    box-sizing: border-box;
}


.item {
    padding: 0px 0px;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.1);
    cursor: pointer;
}

.item.selected {
    background-color: rgb(246, 246, 246);
}

.itemContent {
    display: flex;
    align-items: center;
    padding: 16px 0px;
    /* padding-bottom: 16px; */
    box-sizing: border-box;
}

.itemPhoto {
    margin-right: 16px; /* 8 indent for nothing */
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    /* background-color: rgba(122, 193, 66, 1.0); */
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px 26px;
    
    /* border-radius: 5.5px; */
}

.itemInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.itemNameContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.itemName {
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    max-height: 46px;
    overflow: hidden;
}

.itemDate {
    flex-shrink: 0;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.1px;
    color: rgb(128, 128, 128);
}

.itemPreview {
    margin-top: 2px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}




.badgeItems {
    width: 100%;
}

.badgeItem {
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.1);
    cursor: pointer;
}

.badgeItem.selected {
    background-color: rgb(246, 246, 246);
}

.badgeItemContent {
    display: flex;
    width: 100%;
    /* align-items: center; */
    padding: 16px 0px;
    /* padding-bottom: 16px; */
    box-sizing: border-box;
}

.badgeItemPhoto {
    margin-right: 16px; /* 8 indent for nothing */
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px 26px;
}

.badgeItemInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.badgeItemNameContent {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
}

.badgeItemName {
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    max-height: 46px;
    overflow: hidden;
}

.badgeItemDate {
    flex-shrink: 0;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.1px;
    color: rgb(128, 128, 128);
}

.badgeItemPreview {
    margin-top: 2px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}

.surveyActions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    display: flex;
    padding: 0px 20px;
    box-sizing: border-box;
    /* width: 100%;
    background-color: aliceblue; */
}

.surveyAction {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    /* margin-top: 8px; */
    margin-left: 8px;
    /* width: 100%; */
    height: 44px;
    padding: 0px 30px;
    background-color: rgba(122, 193, 66, 1.0);
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s opacity ease;
}

.surveyAction.secondary {
    margin-right: 8px;
    margin-left: 0px;
    color: rgba(40, 42, 45, 1.0);
    background-color: rgba(246, 246, 246, 1.0);
}

.surveyAction:hover {
    opacity: 0.8;
}



@media (min-width: 900px) {
    .item {
        padding: 0px 0px;
    }

    .awardItem {
        padding: 0px 24px;
    }

    .badgeItem {
        padding: 0px 24px;
    }

    .profile {
        padding-left: 20px;
        padding-right: 20px;
    }

    .sectionContent {
        padding-left: 20px;
        padding-right: 20px;
    }

    .listContent {
        padding-left: 24px;
        padding-right: 24px;
    }

    .actions {
        padding-left: 24px;
        padding-right: 24px;
    }
}