
.TitleBar {
    width: 100%;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.5);
}

.brand {
    display: none;
    margin-right: 32px;
    width: 54px;
    height: 54px;
    background-color: rgba(246, 246, 246, 0.1);
}
