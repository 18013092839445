
.ListHeader {
    padding: 0px 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    transition: 0.3s opacity ease;
}

.ListHeader:hover {
    /* opacity: 0.8; */
}

.headerContent {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    padding: 16px 16px;
    box-sizing: border-box;
    background-color: rgb(246, 246, 246);
    /* border: 1px solid rgba(40, 42, 45, 0.1); */
    border-radius: 12px;
    cursor: pointer;
}

.headerIcon {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    /* background-color: gray; */
}

.headerTitle {
    /* margin-top: 10px; */
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    max-height: 46px;
    overflow: hidden;
}

.headerSubtitle {
    margin-top: 2px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}
