.loaderWrapper{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.welcomeMessage{
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}
.welcomeMessage h1{
  font-size: 16px;
  color: #434343;
  font-weight: bold;
}
.welcomeMessage p{
  font-size: 14px;
  color:#888
}

.loader{
  width: 50px;
  height: 50px;
  border: 5px solid #7ac142;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin .5s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}