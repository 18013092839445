
.FeedList {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
}

.items {
    padding: 0px 0px;
    box-sizing: border-box;
}

.input {
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 16px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.15px;
}

.input::placeholder {
    color: rgb(128, 128, 128);
}

/* .item {
    padding: 0px 16px;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.1);
    cursor: pointer;
} */

.item {
    /* margin-bottom: 16px; */
    display: flex;
    align-items: center;
    padding: 0px 16px;
    box-sizing: border-box;
    /* border-radius: 10px; */
    background-color: rgb(255, 255, 255);
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.1);
    /* background-color: rgb(255, 255, 255); */
    /* background-color: rgb(229 244 235); */
    /* box-shadow: 0px 0px 16px rgba(40, 42, 45, 0.05); */
    /* border: 2.5px solid rgba(116, 87, 246, 1.0); */
    /* border: 0.5px solid rgb(230, 230, 230); */
    /* border-bottom: 1px solid rgb(240, 240, 240); */
    /* border: 0.5px solid rgba(40, 42, 45, 0.1); */
    cursor: pointer;
}

.item.selected {
    background-color: rgb(246, 246, 246);
}

.itemContent {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 16px 0px;
    /* padding-bottom: 16px; */
    box-sizing: border-box;
}

.itemPhoto {
    margin-top: 5px;
    margin-right: 16px; /* 8 indent for nothing */
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    /* background-color: rgba(128, 128, 128, 0.1); */
    background-color: rgba(240, 240, 240, 1.0);
    background-size: cover;
    box-sizing: border-box;
    border-radius: 22px;
}

.itemInfo {
    display: flex;
    width: 100%;
}

.itemNameContent {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100%;
}

.itemName {
    font-size: 17px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.2px;
}

.itemDate {
    flex-shrink: 0;
    margin-left: 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: rgb(128, 128, 128);
}

.itemPreview {
    margin-top: 2px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.15px;

    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}

.itemActions {
    margin-top: 10px;
    margin-bottom: 8px;
    width: 100%;
    /* height: 40px; */
    /* background-color: gray;   */
}

.itemActions {
    display: flex;
    margin-top: 10px;
    /* padding: 0px 16px; */
    box-sizing: border-box;
}

.itemAction {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    /* width: 100%; */
    height: 44px;
    padding: 0px 30px;
    background-color: rgba(122, 193, 66, 1.0);
    border-radius: 22px;
    color: white;
    cursor: pointer;
    transition: 0.3s opacity ease;
}

.itemActionSecondary {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    /* width: 100%; */
    height: 44px;
    padding: 0px 30px;
    background-color: rgb(240, 240, 240);
    border-radius: 22px;
    color: rgb(40, 42, 45);
    cursor: pointer;
    transition: 0.3s opacity ease;
}

.itemAction:hover {
    opacity: 0.8;
}

.itemActionSecondary:hover {
    opacity: 0.8;
}

.itemActionTitle {
    font-size: 14px;
    font-weight: 600;
    /* color: white; */
}

.emailMuted{
    font-size: 10px;
    color: #999;
}

@media (min-width: 900px) {
    .FeedList {

    }

    .item {
        padding: 0px 24px;
    }

    .itemActions {
        display: none;
    }
}