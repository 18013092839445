@tailwind base;
@tailwind components;
@tailwind utilities;

.Home {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
}

.items {
    padding: 0px 0px;
    padding-bottom: 6px;
    box-sizing: border-box;
}

/* .item {
    padding: 0px 16px;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.1);
    cursor: pointer;
} */

.item {
    /* margin-bottom: 16px; */
    display: flex;
    align-items: center;
    padding: 0px 16px;
    box-sizing: border-box;
    /* border-radius: 10px; */
    background-color: rgb(255, 255, 255);
    /* background-color: rgb(255, 255, 255); */
    /* background-color: rgb(229 244 235); */
    /* box-shadow: 0px 0px 16px rgba(40, 42, 45, 0.05); */
    /* border: 2.5px solid rgba(116, 87, 246, 1.0); */
    /* border: 0.5px solid rgb(230, 230, 230); */
    /* border-bottom: 1px solid rgb(240, 240, 240); */
    /* border: 0.5px solid rgba(40, 42, 45, 0.1); */
    cursor: pointer;
}

.item.selected {
    background-color: rgb(246, 246, 246);
}

.itemContent {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px 0px;
    /* padding-bottom: 16px; */
    box-sizing: border-box;
    width: 100%;
}

.itemPhoto {
    position: relative;
    margin-top: 5px;
    margin-right: 16px; /* 8 indent for nothing */
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    background-color: rgba(240, 240, 240, 1.0);
    box-sizing: border-box;
    border-radius: 22px;
    overflow: hidden;
}

.itemPhoto img {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.itemInfo {
    display: flex;
    width: 100%;
}

.itemNameContent {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100%;
}

.itemNameContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.itemName {
    font-size: 17px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.2px;
}

.itemDate {
    flex-shrink: 0;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.1px;
    color: rgb(128, 128, 128);
}

.itemPreview {
    margin-top: 2px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(100, 100, 100);
}

.itemMedia {
    margin-top: 16px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.itemMedia img {
    display: block;
    width: 100%;
}

.itemActions {
    margin-top: 12px;
    width: 100%;
    height: 40px;
    background-color: gray;
}

.featured {
    padding: 0px 16px;
    margin-bottom: 12px;
    box-sizing: border-box;
}

.featuredCard {
    display: flex;
    align-items: flex-start;
    /* background-color: gray; */
    padding: 16px 16px;
    box-sizing: border-box;
    /* background-color: rgb(240, 240, 240); */
    border: 1px solid rgb(240, 240, 240);
    border-radius: 16px;
}

.featuredInfo {
    width: calc(100% - 48px);
}

.featuredIcon {
    margin-top: 5px;
    width: 36px;
    height: 36px;
    background-size: 100% 100%;
    margin-right: 12px;
    flex-shrink: 0;
}

.featuredTitle {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.2px;
}

.featuredSubtitle {
    margin-top: 0px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(100, 100, 100);
}

.surveys {
    padding: 0px 16px;
    margin-bottom: 12px;
    box-sizing: border-box;
}

.surveyCard {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* margin-top: 16px; */
    /* background-color: gray; */
    padding: 16px 16px;
    box-sizing: border-box;
    background-color: rgb(246, 246, 246);
    /* border: 1px solid rgb(240, 240, 240); */
    border-radius: 16px;
}

.surveyCard:first-of-type {
    margin-top: 0px;
}

.surveyHeader {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.surveyActions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2px;
    display: flex;
    /* width: 100%;
    background-color: aliceblue; */
}

.surveyAction {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    margin-right: 8px;
    /* width: 100%; */
    height: 44px;
    padding: 0px 30px;
    background-color: rgba(122, 193, 66, 1.0);
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s opacity ease;
}

.surveyAction:hover {
    opacity: 0.8;
}

.likertLegend {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
}

.likertItem {
    width: 120px;
    font-size: 12px;
    font-weight: 600;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(100, 100, 100);
}

.likertAction {
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 1;
    width: 20%;
    margin-top: 8px;
    margin-bottom: 8px;
    /* margin-right: 8px; */
    color: white;
    cursor: pointer;
    /* background-color: black; */
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s opacity ease;
}

.likertAction:hover {
    opacity: 0.8;
}

.likertCircle {
    margin-top: 5px;
    width: 16px;
    height: 16px;
    border: 2.5px solid gray;
    border-radius: 20px;
}

.likertTitle {
    margin-top: 5px;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: rgb(128, 128, 128);
}

.surveyConfirm {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    display: flex;
}

.surveyConfirmIcon {
    width: 26px;
    height: 26px;
    background-size: 100% 100%;
}

.surveyConfirmText {
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 1px;
    font-size: 15px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    overflow: hidden;
    /* color: rgb(100, 100, 100); */
}






.shortcuts {
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
    /* margin-top: 20px; */
    margin-bottom: 32px;
}

.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding: 0px 4px; */
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
}

.shortcut {
    display: flex;
    align-items: center;
    width: 100%;
    /* margin-bottom: 16px; */
    color: rgb(40, 42, 45);
    padding: 12px 12px;
    background-color: rgba(122, 193, 66, 0.16);
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity, transform;
    opacity: 1.0;
    transition: 0.3s opacity ease;
}

.shortcut:hover {
    opacity: 0.7;
}

.shortcutSearch {
    display: flex;
    align-items: center;
    width: 100%;
    /* margin-bottom: 16px; */
    color: rgb(40, 42, 45);
    padding: 12px 12px;
    background-color: rgba(122, 193, 66, 0.16);
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity, transform;
    opacity: 1.0;
    transition: 0.3s opacity ease;
}

.search {
    background-color: transparent;
    margin-bottom: 0px;
    color: rgb(40, 42, 45);
}

.animated {
    opacity: 0.0;
    animation-name: entrance;
    animation-delay: 0.0s;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
}

.shortcut.a {
    /* animation-delay: 0.7s; */
}

.shortcut.b {
    /* animation-delay: 0.9s; */
}

.shortcut.c {
    /* animation-delay: 1.1s; */
}

.shortcutInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 680px;
    width: 100%;
    box-sizing: border-box;
}

.shortcutIcon {
    margin-right: 4px;
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28px 28px;
    /* background-color: brown; */
}

.shortcutIconFas{
    margin-right: 4px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shortcutIconFas svg{
    width: 24px;
    height: 24px;
    color: #7ac142;
}
.shortcutTitle {
    display: block;
    font-size: 17px;
    line-height: 23px;
    font-weight: 600;
    letter-spacing: -0.5px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 48px;
}

.shortcutDescription {
    display: block;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 24px;
    max-height: 48px;
    opacity: 0.7;
}

.ratingOptions {
    width: 100%;
    margin-top: 16px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 16px;
    /* justify-content: space-between; */
}

.surveyCard {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* background-color: gray; */
    padding: 16px 16px;
    padding-bottom: 20px;
    /* padding-right: 8px; */
    box-sizing: border-box;
    /* background-color: rgb(240, 240, 240); */
    /* border: 1px solid rgb(240, 240, 240); */
    border-radius: 12px;
}

.subheading {
    font-size: 26px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -1px;
    margin-bottom: 16px;
    /* max-width: 240px; */
}

.search {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.5px;
    /* margin-bottom: 16px; */
    color: rgb(40, 42, 45);
    /* max-width: 240px; */
}

.search::placeholder {
    color: rgb(40, 42, 45);
    opacity: 0.7;
}

.ratingOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-end; */
    /* margin-top: 20px; */
    /* margin-right: 20px; */
    /* width: 100px; */
    padding: 12px 0px;
    /* height: 72px; */
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    background-position: center;
    background-size: 36px;
    background-repeat: no-repeat;

    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 24px;

    cursor: pointer;
    /* max-height: 48px; */
    /* opacity: 0.7; */
}

.ratingOptionIcon {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    /* background-color: rgba(246, 246, 246, 1.0); */
    background-position: center;
    background-size: 32px 32px;
    background-repeat: no-repeat;
}

.ratingOptionPercent {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.ratingOptionChange {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    opacity: 0.7;
}

.recommendationHeader {
    display: flex;
}

.recommendationIcon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    background-position: center;
    /* background-size: 36px 36px; */
    background-repeat: no-repeat;
}

.recommendationInfo {
    display: flex;
    flex-direction: column;
}

.desktopOnly {
    display: none;
}

.chart {
    display: flex;
    width: 100%;
}

.chartLegend {
    margin-right: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 16px);
}

@media (min-width: 900px) {
    .desktopOnly {
        display: block;
    }
}

@keyframes entrance {
    from {
        transform: translateY(12px);
        opacity: 0.0;
    }
    to {
        transform: translateY(0px);
        opacity: 1.0;
    }
}









@media (min-width: 900px) {
    .shortcuts {
        padding: 0px 24px;
    }

    .items {
        padding-bottom: 14px;
    }

    .item {
        padding: 0px 24px;
    }

    .featured {
        padding: 0px 24px;
    }

    .surveys {
        padding: 0px 24px;
    }

    .list {
        grid-template-columns: 1fr 1fr;
    }
}

/*

.items {
    margin-top: 16px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    padding: 0px 16px;
    box-sizing: border-box;
}

.item {
    display: flex;
    align-items: center;
    padding: 16px 16px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 16px rgba(40, 42, 45, 0.05);
    border: 1px solid rgb(240, 240, 240);
    cursor: pointer;
}

.item.selected {
    background-color: rgb(246, 246, 246);
}

.itemContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px;
    box-sizing: border-box;
}

.itemTop {
    display: flex;
    width: 100%;
    opacity: 0.8;
}

.itemIcon {
    margin-right: 16px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' viewBox='0 -960 960 960' width='48'%3E%3Cpath d='M80-80v-740q0-24 18-42t42-18h437q-4 14-6 29.5t-1 30.5H140v600l74-80h606v-349q16.794-5 31.397-13.5Q866-671 880-682v382q0 24-18 42t-42 18H240L80-80Zm60-740v520-520Zm619.882 101Q714-719 682-751.118q-32-32.117-32-78Q650-875 682.118-907q32.117-32 78-32Q806-939 838-906.882q32 32.117 32 78Q870-783 837.882-751q-32.117 32-78 32Z' fill='%23282A2D' /%3E%3C/svg%3E");
    box-sizing: border-box;
    background-position: top left;
    background-size: 100% 100%;
}

.itemName {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: rgba(40, 42, 45, 0.7);
}

.itemMain {
    display: flex;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.2px;
}

.itemBottom {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 4px;
    width: 100%;
}

.itemPreview {
    margin-top: 2px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}

.itemAction {
    margin-top: 16px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(255, 255, 255);
    padding: 0px 16px;
    background-color: rgba(122, 193, 66, 1.0);
    border: 2px solid rgb(122, 193, 66);
    border-radius: 10px;
    color: rgba(255, 255, 255, 1.0);
}

.itemArrow {
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
}

.itemPhoto {
    margin-right: 12px;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    background-color: rgba(122, 193, 66, 1.0);
    box-sizing: border-box;
    border-radius: 5px;
}

.itemInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.itemNameContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.itemDate {
    flex-shrink: 0;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.1px;
    color: rgb(128, 128, 128);
}

@media (min-width: 900px) {
    .items {
        padding: 0px 24px;
        margin-bottom: 24px;
        grid-template-columns: 1fr 1fr;
    }

    .item {
        
    }
}
*/