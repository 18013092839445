
.UserSuggestions {

}

.items {
    padding: 0px 24px;
    box-sizing: border-box;
}

.item {
    /* padding: 0px 16px;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.1);
    cursor: pointer; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 16px;
    /* background-color: gray; */
    padding: 16px 16px;
    box-sizing: border-box;
    background-color: rgb(246, 246, 246);
    /* border: 1px solid rgb(240, 240, 240); */
    border-radius: 12px;
    cursor: pointer;
}

.item:last-of-type {
    margin-bottom: 24px;
}

.item.selected {
    background-color: rgb(246, 246, 246);
}

.itemContent {
    display: flex;
    align-items: flex-start;
    padding: 16px 0px;
    /* padding-bottom: 16px; */
    box-sizing: border-box;
}

.itemPhoto {
    margin-right: 14px; /* 8 indent for nothing */
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-color: rgba(246, 246, 246, 1.0);
    background-size: cover;
    box-sizing: border-box;
    border-radius: 20px;
}

.itemInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.itemNameContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.itemName {
    margin-bottom: 0px;
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    max-height: 46px;
    overflow: hidden;
}

.itemDate {
    flex-shrink: 0;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.1px;
    color: rgb(128, 128, 128);
}

.itemPreview {
    margin-top: 2px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}

.itemBadge {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.7;
    line-height: 32px;
}

.itemBadgeIcon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-right: 5px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px 26px;
}

@media (min-width: 900px) {
    .item {
        padding: 0px 24px;
    }
}