
.SignIn {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    opacity: 0.0;
    pointer-events: none;
}

.SignIn.visible {
    pointer-events: auto;
    opacity: 1.0;
}

.content {
    padding: 16px;
    box-sizing: border-box;
}

.brandIcon {
    width: 100px;
    height: 80px;
    background-size: 100% 100%;
}

.items {
    padding: 0px 0px;
    box-sizing: border-box;
}

.item {
    padding: 0px 16px;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.1);
    cursor: pointer;
}

.item.selected {
    background-color: rgb(246, 246, 246);
}

.itemContent {
    display: flex;
    align-items: center;
    padding: 16px 0px;
    /* padding-bottom: 16px; */
    box-sizing: border-box;
}

.itemPhoto {
    margin-right: 12px; /* 8 indent for nothing */
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    background-color: rgba(122, 193, 66, 1.0);
    box-sizing: border-box;
    border-radius: 5px;
}

.itemInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.itemNameContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* .itemName {
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    max-height: 46px;
    overflow: hidden;
} */

.itemName {
    margin-top: 16px;
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -1px;
}

.itemTitle {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}

.itemDate {
    flex-shrink: 0;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.1px;
    color: rgb(128, 128, 128);
}

.itemPreview {
    margin-top: 2px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}

.page {
    margin-top: 12px;
    display: none;
    /* background-color: gray; */
}

.page.visible {
    display: flex;
}

.info {
    margin-top: 72px;
    margin-bottom: 12px;
}

.title {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -1px;
}

.description {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: rgb(128, 128, 128);
}

.fields {
    margin-top: 20px;
}

.field {
    width: 100%;
    height: 44px;
}

.field input {
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1.5px solid rgba(40, 42, 45, 0.08);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.2px;
    background-color: transparent;
}

.field input::placeholder {
    color: rgb(128, 128, 128);
}

.field input:focus {
    border: 1.5px solid rgba(122, 193, 66, 1.0);
}

.actions {
    margin-top: 24px;
}

.button {
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.2px;
    background-color: rgba(122, 193, 66, 1.0);
    color: white;
    text-align: center;
    border-radius: 10px;
    transition: 0.3s opacity ease;
    cursor: pointer;
}

.button:hover {
    opacity: 0.8;
}

.tokenDetected{
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    color: #f87171;
    cursor: pointer;
}

@media (min-width: 900px) {
    .SignIn {
        display: flex;
        justify-content: center;
    }

    .content {
        width: 420px;
    }

    .item {
        padding: 0px 24px;
    }
}