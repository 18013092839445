
.NavigationView {
    width: 100%;
    height: 100%;
    position: relative;
}

.navigationBar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    top: 0px;
    width: 100%;
    height: calc(env(safe-area-inset-top) + 60px);
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(24px);
    padding: 0px 16px;
    padding-top: env(safe-area-inset-top);
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.0);
    transform: 0.3s border-color ease;
}

.NavigationView.modal .navigationBar {
    padding-top: 0px;
    height: 60px;
}

.navigationBar.visible {
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.1);
}

.accessory {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: calc(env(safe-area-inset-bottom) + 60px);
    left: 0px;
    /* top: 0px; */
    width: 100%;
    min-height: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(24px);
    padding: 0px 16px;
    /* padding-top: env(safe-area-inset-top); */
    box-sizing: border-box;
    border-top: 0.5px solid rgba(40, 42, 45, 0.1);
    transform: 0.3s border-color ease;
}

.accessory.keyboard {
    bottom: 0px;
}

.content {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding-top: calc(env(safe-area-inset-top) + 60px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
    box-sizing: border-box;
    overflow: scroll;
}

.NavigationView.modal .content {
    padding-top: 60px;
    padding-bottom: 0px;
}

.content.withAccessory {
    padding-bottom: calc(env(safe-area-inset-bottom) + 60px + 50px);
}

.content.keyboard {
    padding-bottom: 0px;
}

.content.keyboard.withAccessory {
    padding-bottom: 50px;
}

.content.bottom {
    display: flex;
    flex-direction: column-reverse;
}

.content::-webkit-scrollbar {
    display: none;
}

.header {
    display: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
}

.header.visible {
    display: block;
}

.headerTitle {
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -1px;
    /* max-width: 240px; */
}

.headerSubtitle {
    margin-top: 10px;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}

.title {
    margin-left: 0px;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.2px;
    opacity: 0.0;
    transition: 0.3s opacity ease;
}

.title.visible {
    opacity: 1.0;
}

.title.persist {
    opacity: 1.0;
}

.space {
    flex-grow: 1;
}

.headerSignout{
    display: none;
    gap: 10px;
    cursor: pointer;
    align-items: center;
}

.actions {
    display: flex;
    z-index: 9999;
    position: relative;
}

.action {
    margin-left: 12px;
    display: flex; 
    align-items: center;
    gap: 10px;
    cursor: pointer;
    /* height: 40px; */
    /* border-radius: 20px; */
}

.action.primary {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 0px 20px;
    color: white;
    background-color: rgba(122, 193, 66, 1.0);
}

.actionIcon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center;
}

.actionName {
    flex-shrink: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 23px;
    letter-spacing: -0.2px;
}

.back {
    width: 36px;
    height: 36px;
    border-radius: 8px;
}

.backIcon {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left center;
}

@media (min-width: 900px) {
    .NavigationView.main {
        /* width: 67%; */
        width: calc(100% - 340px);
    }

    .NavigationView.primary {
        width: 340px;
    }

    .NavigationView.secondary .back {
        display: none;
    }

    .NavigationView.side .back {
        display: none;
    }

    .content {
        padding-bottom: 0px;
    }

    .content.withAccessory {
        padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
    }

    .accessory {
        bottom: 0px;
        padding: 0px 24px;
    }
    
    /* .content.keyboard {
        padding-bottom: 0px;
    } */
    
    /* .content.keyboard.withAccessory {
        padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
    } */

    .navigationBar {
        padding: 0px 24px;
    }

    .NavigationView.modal .navigationBar {
        padding: 0px 20px;
    }

    .header {
        padding-left: 24px;
        padding-right: 24px;
    }

    .NavigationView.modal .header {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media(max-width: 899px){
    .headerSignout{
        display: flex;
    }
}

@media (min-width: 1400px) {
    .NavigationView.main {
        /* width: 67%; */
        width: calc(100% - 420px);
    }

    .NavigationView.primary {
        width: 420px;
    }
}
