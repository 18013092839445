
.AddTicketModal {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #fffffff3;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);

    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity, transform;

    transition: 0.3s opacity ease, 0.5s transform ease;
    opacity: 0.0;
    transform: translateX(40px) scale(1.0);
    pointer-events: none;
}

.AddTicketModal.visible {
    opacity: 1.0;
    transform: translateX(0px) scale(1.0);
    pointer-events: auto;
}

.content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.page {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding-top: calc(env(safe-area-inset-top));
    padding-bottom: calc(48px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    overflow: scroll;
    scrollbar-width: none;
    /* background-color: green; */
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity, transform;

    transition: none;
    opacity: 0.0;
    transform: translateX(0px) scale(1.0);
    pointer-events: none;
}

.page::-webkit-scrollbar {
    display: none;
}

.header {
    /* margin-top: 20px; */
    margin-bottom: 32px;
    padding: 0px 20px;
    box-sizing: border-box;
}

.header .back {
    width: 36px;
    height: 44px;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%232F2F2F'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z'/%3E%3C/svg%3E");
    background-position-x: left;
    background-position-y: center;
    background-repeat: no-repeat;
}

.header .space {
    margin-bottom: 12px;
    width: 36px;
    height: 44px;
    /* background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%232F2F2F'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z'/%3E%3C/svg%3E"); */
    background-position-x: left;
    background-position-y: center;
    background-repeat: no-repeat;
}

.header h2 {
    margin-top: 20px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    letter-spacing: -1px;
}

.header p {
    margin-top: 16px;
    font-size: 17px;
    font-weight: 500;
    text-overflow: ellipsis;
    word-wrap: break-word;
    line-height: 28px;
    opacity: 0.8;
}

.categories {
    display: flex;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.confirmInfo {
    display: flex;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;


    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.checkIcon {
    margin-top: 120px;
    width: 48px;
    height: 48px;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='%235ACC89'%3E%3Crect fill='none' height='24' width='24'/%3E%3Cpath d='M22,5.18L10.59,16.6l-4.24-4.24l1.41-1.41l2.83,2.83l10-10L22,5.18z M19.79,10.22C19.92,10.79,20,11.39,20,12 c0,4.42-3.58,8-8,8s-8-3.58-8-8c0-4.42,3.58-8,8-8c1.58,0,3.04,0.46,4.28,1.25l1.44-1.44C16.1,2.67,14.13,2,12,2C6.48,2,2,6.48,2,12 c0,5.52,4.48,10,10,10s10-4.48,10-10c0-1.19-0.22-2.33-0.6-3.39L19.79,10.22z'/%3E%3C/svg%3E");
}


.messages {
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
}

.category {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: rgba(47, 47, 47, 1.0);
    padding: 16px 16px;
    background-color: rgba(90, 204, 137, 0.12);
    border-radius: 20px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity, transform;
    opacity: 1.0;
    transition: 0.3s opacity ease;
}

.category:hover {
    opacity: 0.7;
}

.info {
    box-sizing: border-box;
}

.icon {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36px 36px;
}

.title {
    margin-bottom: 8px;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 24px;
    text-align: center;
}

.description {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(47, 47, 47, 0.5);
    line-height: 20px;
    text-align: center;
}

.field {
    width: 100%;
    font-size: 17px;
    line-height: 32px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: rgb(47, 47, 47);
    padding: 16px 20px;
    border-radius: 12px;
    box-sizing: border-box;
    /* border-top: 1px solid rgba(47, 47, 47, 0.05); */
    box-shadow: 0px 2px 24px rgba(47, 47, 47, 0.1);
    background-color: white;
    border-bottom: none;
    transition: 0.3s border ease;
    resize: none;
}

.field.visible {
    display: block;
}

.field::placeholder {
    color: rgba(47, 47, 47, 0.5);
}

.button {
    position: relative;
    width: 100%;
    height: 50px;
    margin-top: 24px;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 50px;
    border-radius: 12px;
    background-color: rgba(90, 204, 137, 1.0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity, transform;
    cursor: pointer;
    color: white;
    text-align: center;
    transition: 0.3s opacity ease;
}

.button.entrance {
    opacity: 0.0;
    animation-name: entrance-up;
    animation-delay: 1.5s;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
}

.button.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.button:hover {
    opacity: 0.8;
}

.confirmation {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 11;
    pointer-events: none;
}

.icon.payroll {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 20 20' height='48px' viewBox='0 0 20 20' width='48px' fill='%232F2F2F'%3E%3Crect fill='none' height='20' width='20'/%3E%3Cpath d='M13.21,3.61C13.11,3.95,13,4.35,13,5.12L15.88,8h0.62v2.8l-1.95,0.43l-1.19,4.27H11.5V14H7v1.5H5.13 C4.49,13.26,3.5,9.46,3.5,8c0-1.65,1.35-3,3-3l4.9,0C11.73,4.43,12.33,3.85,13.21,3.61z M14,2c-2.17,0-3.35,1.5-3.35,1.5H6.5 C4.05,3.5,2,5.47,2,8c0,2.33,2,9,2,9h4.5v-1.5H10V17h4.5l1.25-4.5L18,12V6.5h-1.5l-2-2C14.5,4.14,15,3.52,15,3C15,2.45,14.55,2,14,2 L14,2z M11,7.5H7V6h4V7.5z M13.25,9c-0.41,0-0.75-0.34-0.75-0.75c0-0.41,0.34-0.75,0.75-0.75S14,7.84,14,8.25 C14,8.66,13.66,9,13.25,9z'/%3E%3C/svg%3E");
}

.icon.benefits {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='48px' viewBox='0 0 24 24' width='48px' fill='%232F2F2F'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-6.74L16.71 6H6.29l5.21-2.74m0-2.26L2 6v2h19V6l-9.5-5z'/%3E%3C/svg%3E");
}

.icon.risk {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='48px' viewBox='0 0 24 24' width='48px' fill='%232F2F2F'%3E%3Cg%3E%3Crect fill='none' height='24' width='24'/%3E%3C/g%3E%3Cg%3E%3Cpath d='M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2z M18,11.09c0,4-2.55,7.7-6,8.83 c-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25l6,2.25V11.09z'/%3E%3C/g%3E%3C/svg%3E");
}

.icon.recruiting {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 20 20' height='48px' viewBox='0 0 20 20' width='48px' fill='%232F2F2F'%3E%3Cg%3E%3Crect fill='none' height='20' width='20' y='0'/%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M16.5,6H12V3.5C12,2.67,11.33,2,10.5,2h-1C8.67,2,8,2.67,8,3.5V6H3.5C2.67,6,2,6.67,2,7.5v9C2,17.33,2.67,18,3.5,18h13 c0.83,0,1.5-0.67,1.5-1.5v-9C18,6.67,17.33,6,16.5,6z M9.5,3.5h1v4h-1V3.5z M16.5,16.5h-13v-9H8C8,8.33,8.67,9,9.5,9h1 C11.33,9,12,8.33,12,7.5h4.5V16.5z'/%3E%3C/g%3E%3Cg%3E%3Crect height='1.5' width='3' x='12' y='10'/%3E%3C/g%3E%3Cg%3E%3Crect height='1.5' width='3' x='12' y='12.5'/%3E%3C/g%3E%3Cg%3E%3Ccircle cx='7.5' cy='11.25' r='1.25'/%3E%3C/g%3E%3Cg%3E%3Cpath d='M9.24,13.36C8.7,13.13,8.12,13,7.5,13c-0.62,0-1.2,0.13-1.74,0.36C5.3,13.56,5,14.01,5,14.52V15h5v-0.48 C10,14.01,9.7,13.56,9.24,13.36z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.icon.sticky {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%232F2F2F'%3E%3Cpath d='M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z'/%3E%3C/svg%3E");
}

.icon.pto {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%232F2F2F'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M21 19.57l-1.427 1.428-6.442-6.442 1.43-1.428zM13.12 3c-2.58 0-5.16.98-7.14 2.95l-.01.01c-3.95 3.95-3.95 10.36 0 14.31l14.3-14.31C18.3 3.99 15.71 3 13.12 3zM6.14 17.27C5.4 16.03 5 14.61 5 13.12c0-.93.16-1.82.46-2.67.19 1.91.89 3.79 2.07 5.44l-1.39 1.38zm2.84-2.84C7.63 12.38 7.12 9.93 7.6 7.6c.58-.12 1.16-.18 1.75-.18 1.8 0 3.55.55 5.08 1.56l-5.45 5.45zm1.47-8.97c.85-.3 1.74-.46 2.67-.46 1.49 0 2.91.4 4.15 1.14l-1.39 1.39c-1.65-1.18-3.52-1.88-5.43-2.07z'/%3E%3C/svg%3E");
}

.icon.other {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='48px' viewBox='0 0 24 24' width='48px' fill='%232F2F2F'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'/%3E%3C/svg%3E");
}
