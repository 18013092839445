
.NavigationBar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
}

.title {
    font-size: 17px;
    font-weight: 600;
}

.space {
    flex-grow: 1;
}

.action {
    height: 40px;
    padding: 0px 16px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(40, 42, 45, 0.08);
    font-size: 15px;
    line-height: 38px;
    font-weight: 600;
    /* background-color: rgb(66, 154, 98); */
}

@media (min-width: 900px) {
    .action {
        padding: 0px 24px;
    }
}
