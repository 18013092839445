
.FeedView {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 254, 252);
    opacity: 0.0;
    transform: translateX(40px);
    pointer-events: none;
    transition: 0.2s opacity ease, 0.3s transform ease;
}

.FeedView.visible {
    opacity: 1.0;
    transform: translateX(0px);
    pointer-events: auto;
}

.items {
    padding: 0px 0px;
    box-sizing: border-box;
}

.item {
    display: flex;
    align-items: center;
    padding: 16px 16px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    /* background-color: rgb(255, 255, 255); */
    /* background-color: rgb(229 244 235); */
    box-shadow: 0px 0px 16px rgba(40, 42, 45, 0.05);
    /* border: 2.5px solid rgba(116, 87, 246, 1.0); */
    /* border: 0.5px solid rgb(230, 230, 230); */
    border: 1px solid rgb(240, 240, 240);
    /* border: 0.5px solid rgba(40, 42, 45, 0.1); */
    cursor: pointer;
}

.item.selected {
    background-color: rgba(39, 255, 75, 0.1);
}

.itemContent {
    display: flex;
    padding: 10px 0px;
    /* padding-bottom: 16px; */
    box-sizing: border-box;
}

.itemPhoto {
    /* display: none; */
    margin-top: 0px;
    margin-right: 16px; /* 8 indent for nothing */
    width: 4px;
    /* height: 40px; */
    height: 80px;
    flex-shrink: 0;
    background-color: rgba(240, 240, 240, 1.0);
    box-sizing: border-box;
    border-radius: 12px;
}

.itemInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.itemNameContent {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
}

.itemName {
    font-size: 16px;
    font-weight: 600;
    /* line-height: 26px; */
    /* letter-spacing: -0.2px; */
    /* color: rgb(46, 86, 159); */
}

.itemDate {
    flex-shrink: 0;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.15px;
    color: rgb(128, 128, 128);
    /* opacity: 0.5; */
}

.itemPreview {
    
    margin-top: 7px;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.1px;
    
    /* text-overflow: ellipsis; */
    /* max-height: 48px; */
    overflow: hidden;
    /* color: rgb(128, 128, 128); */
    /* opacity: 0.7; */
}

.item.received {
    /* text-align: left; */
    /* border-bottom: none; */
    /* background: rgba(39, 255, 75, 0.1); */
    /* background: rgba(29, 191, 56, 0.05); */
    /* color: white; */
    /* box-shadow: 0px 2px 2px rgba(40, 42, 45, 0.1); */
    /* box-shadow: 0px 2px 8px rgba(40, 42, 45, 0.08); */
}

.item .itemPhoto {
    background-color: rgba(122, 193, 66, 1.0);
}

.item.received .itemPhoto {
    background-color: rgba(240, 240, 240, 1.0);
}

@media (min-width: 900px) {
    .FeedView {
        left: 340px;
        width: calc(100% - 340px);
        opacity: 1.0;
        pointer-events: auto;
        border-left: 0.5px solid rgba(40, 42, 45, 0.1);
        transform: translateX(0px);
        transition: none;
    }
    
    .item {
        padding: 0px 24px;
    }
}
