.loaderContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between spinner and text */
}

.loaderContainerRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem; /* Space between spinner and text */
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #7ac142;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.3s linear infinite;
}

.text {
  color: black;
  font-size: 16px;
}

@media(max-width: 760px){
  .loaderContainer {
  justify-content: center;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}