
.NotificationsView {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    opacity: 0.0;
    transform: translateX(40px);
    pointer-events: none;
    transition: 0.2s opacity ease, 0.3s transform ease;
}

.NotificationsView.visible {
    opacity: 1.0;
    transform: translateX(0px);
    pointer-events: auto;
}

.items {
    padding: 0px 24px;
    box-sizing: border-box;
}

.item {
    /* padding: 0px 16px;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(40, 42, 45, 0.1);
    cursor: pointer; */

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 16px;
    /* background-color: gray; */
    padding: 16px 16px;
    box-sizing: border-box;
    background-color: rgb(246, 246, 246);
    /* border: 1px solid rgb(240, 240, 240); */
    border-radius: 12px;
    cursor: pointer;
}

.item:last-of-type {
    margin-bottom: 24px;
}

.item.selected {
    background-color: rgb(246, 246, 246);
}

.itemContent {
    display: flex;
    align-items: flex-start;
    padding: 16px 0px;
    /* padding-bottom: 16px; */
    box-sizing: border-box;
}

.itemPhoto {
    margin-right: 14px; /* 8 indent for nothing */
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-color: rgba(246, 246, 246, 1.0);
    background-size: cover;
    box-sizing: border-box;
    border-radius: 20px;
}

.itemInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.itemNameContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.itemName {
    margin-bottom: 8px;
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    max-height: 46px;
    overflow: hidden;
}

.itemDate {
    flex-shrink: 0;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.1px;
    color: rgb(128, 128, 128);
}

.itemPreview {
    margin-top: 2px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.15px;
    text-overflow: ellipsis;
    max-height: 44px;
    overflow: hidden;
    color: rgb(128, 128, 128);
}

.itemBadge {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.7;
    line-height: 32px;
}

.itemBadgeIcon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-right: 5px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px 26px;
}


.surveyActions {
    width: 100%;
    display: grid;
    grid-gap: 12px;
    flex-wrap: wrap;
    margin-top: 12px;
    display: flex;
    /* width: 100%;
    background-color: aliceblue; */
}

.surveyAction {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 8px; */
    /* margin-right: 8px; */
    /* width: 100%; */
    /* height: 44px; */
    padding: 10px 16px;
    background-color: rgba(122, 193, 66, 0.16);
    /* border: 1px solid rgb(240, 240, 240); */
    border-radius: 16px;
    color: rgb(40, 42, 45);
    /* color: rgba(122, 193, 66, 1.0); */
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    transition: 0.3s opacity ease;

    
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-right: 8px; */
        /* width: 100%; */
        height: 44px;
        padding: 0px 16px;
        box-sizing: border-box;
        background-color: rgba(122, 193, 66, 1.0);
        border-radius: 10px;
        color: white;
        cursor: pointer;
        transition: 0.3s opacity ease;
    
}

.surveyAction:hover {
    opacity: 0.8;
}

@media (min-width: 900px) {
    .NotificationsView {
        left: calc(100% - 340px);
        width: 340px;
        opacity: 1.0;
        pointer-events: auto;
        border-left: 0.5px solid rgba(40, 42, 45, 0.1);
        transform: translateX(0px);
        transition: none;
    }
    
    .item {
        padding: 0px 24px;
    }
}

@media (min-width: 1400px) {
    .NotificationsView {
        left: calc(100% - 420px);
        width: 420px;
    }
}
