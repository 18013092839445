.Aggregate {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
}

.subheading {
    font-size: 26px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -1px;
    padding: 10px;
}


.AggregateList {
    width: 100%;
    box-sizing: border-box;
}

.accordion {
    position: relative;
    border-radius: 16px;
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
}

.accordionData {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
}


details > summary {
    list-style: none;
}

details > summary::-webkit-details-marker {
    display: none;
}


details > summary::before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' fill='%23282A2D' width='24'%3E%3Cpath d='M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z'/%3E%3C/svg%3E");
    background-size: 20px auto;
    background-repeat: no-repeat;
    background-position: center;
    padding: 16px;
}

details[open] > summary::before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' fill='%23282A2D' width='24'%3E%3Cpath d='M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z'/%3E%3C/svg%3E");
    background-size: 20px auto;
    background-repeat: no-repeat;
    background-position: center;
    padding: 16px;
}


@media (min-width: 900px) {
    .accordion {
        padding: 0px 24px;
    }

    .accordionData {
        grid-template-columns: 1fr 1fr;
    }
}